<script>
import {
	mdiCalendarOutline, mdiChevronLeft, mdiChevronRight, mdiFormatListBulleted,
	mdiHistory
} from "@mdi/js"
import CardUser from "@/components/Card/CardUser.vue"
import LabelStatus from "@/components/LabelStatus.vue"
import {dictStatuses} from "@/services/mappers"
import DialogHistory from "@/components/Dialog/DialogHistory.vue"
import {
	countDaysInVacations,
	extendVacationRanges,
	getEarliestDate
} from "@/services/helpers"
import ListVacations from "@/components/ListVacations.vue"
import BtnApplicationReject from "@/components/Buttons/BtnApplicationReject.vue";
import BtnApplicationAccept from "@/components/Buttons/BtnApplicationAccept.vue";
import BtnApplicationRejectReschedule from "@/components/Buttons/BtnApplicationRejectReschedule.vue";

export default {
	name: "CardApplicationManager",
	components: {
		BtnApplicationRejectReschedule,
		BtnApplicationAccept,
		BtnApplicationReject, ListVacations, DialogHistory, LabelStatus, CardUser},
	props: {
		application: {
			type: Object,
			required: true,
		},
		holidays: {
			type: Array,
			default: () => [],
		},
		currentYear: {
			type: Number,
			default: 2024,
		},
	},
	data() {
		return {
			dictStatuses,
			icons: {
				history: mdiHistory,
				calendar: mdiCalendarOutline,
				right: mdiChevronRight,
				left: mdiChevronLeft,
				list: mdiFormatListBulleted
			},
			editingApplication: false,
			calendarView: false,
			earliestVacationDate: null,
		}
	},
	computed: {
		vacationsForDisplay() {
			return extendVacationRanges(this.application.vacations)
		},
	},
	async created() {
		const { vacations } = this.application
		this.earliestVacationDate = getEarliestDate(vacations)
	},
	methods: {
		countDaysInVacations,
	}
}
</script>

<template>
	<v-card
		outlined
		class="mb-4"
	>
		<v-card-title>
			<CardUser
				v-if="application.employee"
				:user="application.employee"
			/>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col
					cols="12"
					md="6"
				>
					<template v-if="calendarView">
						<v-date-picker
							v-model="vacationsForDisplay"
							multiple
							readonly
							no-title
							:picker-date.sync="earliestVacationDate"
							:show-current="false"
							width="100%"
							:first-day-of-week="1"
							show-adjacent-months
							:events="holidays"
							event-color="secondary"
						/>
					</template>
					<ListVacations
						v-else
						:vacations="application.vacations"
						:holidays="holidays"
					/>
				</v-col>
				<v-col
					cols="12"
					md="6"
				>
					<p>
						Статус:
						<LabelStatus
							:status="application.status"
						/>
					</p>
					<p>Комментарий: {{ application.comment }}</p>
					<p>Всего дней: {{ countDaysInVacations(application.vacations, holidays) }}</p>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row>
				<v-col
					cols="auto"
					class="d-flex flex-wrap"
				>
					<DialogHistory
						v-if="application.histories.length"
						:application="application"
					>
						<template #activator="{attrs, on}">
							<v-btn
								rounded
								depressed
								class="px-6"
								:disabled="editingApplication"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon class="mr-1">
									{{ icons.history }}
								</v-icon>
								История
							</v-btn>
						</template>
					</DialogHistory>
					<v-btn
						depressed
						rounded
						@click="calendarView	= !calendarView"
					>
						<v-icon>{{ calendarView ? icons.list : icons.calendar }}</v-icon>
					</v-btn>
				</v-col>
				<v-spacer />
				<BtnApplicationAccept
					:application="application"
					:loading="editingApplication"
					@loading="editingApplication = $event"
					@update="$emit('update')"
				/>
				<BtnApplicationReject
					:application="application"
					:loading="editingApplication"
					@loading="editingApplication = $event"
					@update="$emit('update')"
				/>
				<BtnApplicationRejectReschedule
					:application="application"
					:loading="editingApplication"
					@loading="editingApplication = $event"
					@update="$emit('update')"
				/>
			</v-row>
		</v-card-actions>
	</v-card>
</template>