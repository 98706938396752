<script>
import {
	mdiCalendarOutline,
	mdiHistory
} from "@mdi/js"
import {
	getHolidays,
	getVacationApplicationsForManager,
} from "@/services/api/requests"
import CardApplicationManager from "@/components/Card/CardApplicationManager.vue"
import ChartGantt from "@/components/ChartGantt.vue"
import {mapGetters} from "vuex";
import {sortAplications} from "../services/helpers";

export default {
	name: "ManagerView",
	components: {ChartGantt, CardApplicationManager},
	data() {
		return {
			applications: [],
			sortedApplications: [],
			loadingApplications: false,
			currentYear: 2024,
			holidays: [],
			icons: {
				history: mdiHistory,
				calendar: mdiCalendarOutline,
			},
		}
	},
	computed: {
		showNoCurrentApplications() {
			return !this.loadingApplications && this.applications.length === 0
		},
		...mapGetters("User",{
			userRole: "getRole",
		})
	},
	async created() {
		await this.fetchApplications()
	},
	methods: {
		async fetchApplications() {
			this.loadingApplications = true
			const { results } = await getVacationApplicationsForManager()
			this.applications = results
			const { holiday_list } = await getHolidays(this.currentYear)
			this.holidays = holiday_list
			this.loadingApplications = false
			this.sortedApplications = sortAplications(this.applications, this.userRole)
		},
		onUpdate() {
			this.fetchApplications()
			this.$refs.gantt.fetchDiagram()
		},
	},
}
</script>

<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<div class="d-flex justify-space-between">
					<h1>Заявки сотрудников на отпуск в {{ currentYear }} году</h1>
				</div>
			</v-col>
		</v-row>
		<ChartGantt ref="gantt" />
		<v-row>
			<v-col v-if="loadingApplications">
				<v-progress-linear
					indeterminate
					color="primary"
				/>
				<p class="text-center">
					Загрузка...
				</p>
			</v-col>
			<v-col
				v-else-if="showNoCurrentApplications"
				cols="12"
			>
				<v-card
					outlined
				>
					<v-card-title>
						<h3>Нет заявок на отпуск в текущем году</h3>
					</v-card-title>
					<v-card-text>
						<p>У вас настроены уведомления на вашу электронную почту о новых заявках на отпуск</p>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col v-else>
				<CardApplicationManager
					v-for="application in sortedApplications"
					:key="application.id"
					:application="application"
					:holidays="holidays"
					:current-year="currentYear"
					@update="onUpdate"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped lang="scss">
</style>